// The theme file can be used to access values for consistent styling across the site
import { CSSProperties } from 'styled-components';

const twTheme = {
  colour: {
    forest: '#0f2830',
    emerald: '#014751',
    tangerine: '#ef9659',
    mint: '#aff8c8',
    lilac: '#D2C4FB',
    cream: '#f4f1e4',
    white: '#FFFFFF',
    black: '#0C0C0C',
    grey: '#CCCCCC',
    lightGrey: '#F4F4F4',
    midGrey: '#656565',
    darkGrey: '#222222',
    errorRed: '#FB8E8E',
    gradientDark1: 'linear-gradient(#0f2830, #014751)',
    gradientDark2: 'linear-gradient(#014751, #0f2830)',
    gradientDark3: 'linear-gradient(#0f2830, #222222)',
    gradientLight1: 'linear-gradient(#F4F1E4, #FFFFFF)',
    gradientLight2: 'linear-gradient(#FFFFFF, #F4F1E4)',
    gradientCool: 'linear-gradient(#D2C4FB, #AFF8C8)',
    gradientWarm: 'linear-gradient(#D2C4FB, #EF9659)',
    gradientLightGreen:
      'linear-gradient(300deg, #ddd6f9 -6.48%, #dbf9e4 86.82%)',
  },
  fontStyle: {
    article: {
      heading1: {
        fontFamily: 'Roboto',
        fontSize: '60px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '110%' /* 66px */,
      },
      heading2: {
        fontFamily: 'Roboto',
        fontSize: '50px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '110%' /* 66px */,
      },
      heading3: {
        fontFamily: 'Roboto',
        fontSize: '41px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '110%' /* 66px */,
      },
      heading4: {
        fontFamily: 'Roboto',
        fontSize: '35px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '110%' /* 66px */,
      },
      heading5: {
        fontFamily: 'Roboto',
        fontSize: '29px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '110%' /* 66px */,
      },
      heading6: {
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '110%' /* 66px */,
      },
      body: {
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '160%' /* 66px */,
      },
      button: {
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '110%' /* 66px */,
      },
      label: {
        fontFamily: 'Roboto',
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '110%' /* 66px */,
      },
      caption: {
        fontFamily: 'Roboto',
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '110%' /* 66px */,
      },
    },
    desktop: {
      jumbo: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '80px',
        lineHeight: '120%',
        letterSpacing: '-0.015em',
      },
      heading1: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '60px',
        lineHeight: '110%',
        letterSpacing: '-0.015em',
      },
      heading2: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '48px',
        lineHeight: '120%',
        letterSpacing: '0',
      },
      heading3: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '32px',
        lineHeight: '130%',
        letterSpacing: '0',
      },
      heading4: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '24px',
        lineHeight: '150%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      heading5: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '32px',
        lineHeight: '110%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      heading6: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '150%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      body1: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '120%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      body2: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '160%',
        letterSpacing: '0',
      },
      bodyBase: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '20px',
        lineHeight: '180%',
        letterSpacing: '0',
      },
      bodyBaseStrong: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '180%',
        letterSpacing: '0',
      },
      bodySmall: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '160%',
        letterSpacing: '0',
      },
      eyebrow: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '100%',
        letterSpacing: '0.03em',
        textTransform: 'uppercase' as CSSProperties['textTransform'],
      },
      button: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '120%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      label1: {
        fontFamily: 'Roboto',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '150%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      label2: {
        fontFamily: 'Roboto',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      caption: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '110%',
        letterSpacing: '0',
      },
    },
    mobile: {
      jumbo: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '36px',
        lineHeight: '130%',
        letterSpacing: '-0.015em',
      },
      heading1: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '40px',
        lineHeight: '120%',
        letterSpacing: '-0.015em',
      },
      heading2: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '28px',
        lineHeight: '120%',
        letterSpacing: '0',
      },
      heading3: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '24px',
        lineHeight: '160%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      heading4: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '100%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      heading5: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '110%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      heading6: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '140%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      body1: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '140%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      body2: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '160%',
        letterSpacing: '0',
      },
      bodyBase: {
        fontFamily: 'Roboto',
        fontWeight: '200',
        fontSize: '18px',
        lineHeight: '180%',
        letterSpacing: '0',
      },
      bodyBaseStrong: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '180%',
        letterSpacing: '0',
      },
      bodySmall: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '160%',
        letterSpacing: '0',
      },
      eyebrow: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '100%',
        letterSpacing: '0.03em',
        textTransform: 'uppercase' as CSSProperties['textTransform'],
      },
      button: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '110%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      label1: {
        fontFamily: 'Roboto',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '120%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      label2: {
        fontFamily: 'Roboto',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '120%',
        letterSpacing: '0',
      },
      // TODO: check if needed
      caption: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '130%',
        letterSpacing: '0',
      },
    },
  },
  buttons: {
    desktop: {
      buttonFilledLight: {
        padding: '20px',
        backgroundColor: '#AFF8C8',
        border: 'none',
        borderRadius: '100px',
        color: '#0f2830',
        textDecoration: 'none',
      },
      buttonFilledLightHoverStyles: {
        backgroundColor: '#ffffff',
      },
      buttonFilledDark: {
        padding: '20px',
        backgroundColor: '#014751',
        border: 'none',
        borderRadius: '100px',
        color: '#ffffff',
        textDecoration: 'none',
      },
      buttonFilledDarkHoverStyles: {
        backgroundColor: '#0f2830',
      },
      buttonOutlineLight: {
        padding: '20px',
        backgroundColor: 'transparent',
        border: '2px solid #AFF8C8',
        borderRadius: '100px',
        color: '#AFF8C8',
        textDecoration: 'none',
      },
      buttonOutlineLightHoverStyles: {
        backgroundColor: 'rgba(175, 248, 200, 0.08)',
      },
      buttonOutlineDark: {
        padding: '20px',
        backgroundColor: 'transparent',
        border: '2px solid #014751',
        borderRadius: '100px',
        color: '#014751',
        textDecoration: 'none',
      },
      buttonOutlineDarkHoverStyles: {
        backgroundColor: 'rgba(1, 70, 81, .08)',
      },
      buttonOutlineTangerine: {
        padding: '16px 48px 16px 48px',
        backgroundColor: 'transparent',
        border: '2px solid #ef9659',
        borderRadius: '100px',
        color: '#F4F4F4',
        textDecoration: 'none',
      },
      buttonOutlineTangerineHoverStyles: {
        backgroundColor: 'rgba(239, 150, 89, 0.08)',
        color: '#ef9659',
        transition: 'all 300ms ease',
      },
      linkLight: {
        padding: '0 0 1px 0',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#AFF8C8',
        textDecoration: 'none',
      },
      linkDark: {
        padding: '0 0 1px 0',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#014751',
        textDecoration: 'none',
      },
    },
    mobile: {
      buttonFilledLight: {
        padding: '16px',
        backgroundColor: '#AFF8C8',
        border: 'none',
        borderRadius: '100px',
        color: '#0f2830',
        textDecoration: 'none',
      },
      buttonFilledDark: {
        padding: '16px',
        backgroundColor: '#0f2830',
        border: 'none',
        borderRadius: '100px',
        color: '#ffffff',
        textDecoration: 'none',
      },
      buttonOutlineLight: {
        padding: '16px',
        backgroundColor: 'transparent',
        border: '2px solid #AFF8C8',
        borderRadius: '100px',
        color: '#AFF8C8',
        textDecoration: 'none',
      },
      buttonOutlineDark: {
        padding: '16px',
        backgroundColor: 'transparent',
        border: '2px solid #014751',
        borderRadius: '100px',
        color: '#014751',
        textDecoration: 'none',
      },
      buttonOutlineTangerine: {
        padding: '16px 48px 16px 48px',
        backgroundColor: 'transparent',
        border: '2px solid #ef9659',
        borderRadius: '100px',
        color: '#F4F4F4',
        textDecoration: 'none',
      },
      linkLight: {
        padding: '0 0 1px 0',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#ffffff',
        textDecoration: 'none',
      },
      linkDark: {
        padding: '0 0 1px 0',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#014751',
        textDecoration: 'none',
      },
    },
  },
  // These are mainly used for padding each page <section>
  spacing: {
    xs: '10px',
    sm: '20px',
    md: '40px',
    lg: '60px',
    xl: '90px',
    xxl: '120px',
    x1: '4px',
    x2: '8px',
    x3: '12px',
    x4: '16px',
    x5: '20px',
    x6: '24px',
    x7: '28px',
    x8: '32px',
    x9: '36px',
    x12: '48px',
    x15: '60px',
    x16: '64px',
    x18: '72px',
    x20: '80px',
    x24: '96px',
    x28: '112px',
    x30: '120px',
    x35: '140px',
    x40: '160px',
    x43: '172px',
  },
  // The sizes shown here can be used in media queries as the min-width of the device range.
  // mobile is 320px to 640px wide
  // tablet is 641px to 1279px wide
  // desktop is 1280px to 2559px wide
  // XLdesktop is 2560px and up
  screens: {
    mobile: '320px',
    tablet: '641px',
    desktop: '1280px',
    XLdesktop: '2560px',
    maxViewport: '1440px',
    maxBannerHeight: '810px',
  },
};

export default twTheme;
