/* eslint-disable react/display-name */
import React from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { replaceContentfulLineBreaks } from './replaceContentfulLineBreaks';
import { replaceContentfulHtml } from './replaceContentfulHtml';
import { replaceContentfulNonBreakingSpace } from './replaceContentfulNonBreakingSpace';
import ContentfulImage from '../components/ContentfulImage';
import ContentfulLink from '../components/ContentfulLink';
import { IContentLink, IMediaAsset } from '../types/contentfulContentTypes';

const defaultOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Contentful seems to add empty paragraphs at the end of rich-text
      // cf: https://github.com/contentful/rich-text/issues/101
      const isEmptyChildren = children?.toString()?.trim() === '';
      if (isEmptyChildren) {
        return null;
      }

      return <p>{children}</p>;
    },
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      switch (node.data?.target.__typename) {
        case 'ContentfulAsset': {
          const contentfulAsset = node.data?.target as IMediaAsset;
          return (
            <ContentfulImage
              image={contentfulAsset}
              alt={contentfulAsset?.description || ''}
            />
          );
        }
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      return (
        node?.data?.target && (
          <>
            Block embedded entry not support:{' '}
            <pre>{JSON.stringify(node?.data?.target, null, 2)}</pre>
          </>
        )
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      // Casting as any to avoid typescript error
      const link: IContentLink = node.data?.target;
      return link?.icon !== null ? (
        <ContentfulLink link={link}>
          {link?.icon && <ContentfulImage image={link.icon} />}
        </ContentfulLink>
      ) : (
        <ContentfulLink link={link}>{link?.text}</ContentfulLink>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      return (
        <a
          href={`https://${node.data?.target?.file?.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {(node.content?.[0] as any)?.value}
        </a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const link = node?.data?.target as IContentLink;
      return <ContentfulLink link={link}>{children}</ContentfulLink>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const link =
        (node?.data?.target as IContentLink) ||
        (node?.data?.uri as IContentLink);
      return <ContentfulLink link={link}>{children}</ContentfulLink>;
    },
  },
  renderText: (text) => {
    let value: string[] | unknown[] = replaceContentfulLineBreaks(text);
    value = replaceContentfulNonBreakingSpace(value);
    value = replaceContentfulHtml(value);
    return <>{value}</>;
  },
};

export interface ContentfulRichText {
  contentful_id?: string;
  __typename?: string;
  raw?: string;
  references?: unknown[];
}

/**
 * renderRichText
 * Helper function that renders Contentful rich text fields as React elements.
 *
 * @param richText Raw string Contentful rich-text field - A contentful rich text `raw` rich text field.
 * @param options Optional. Contentful rich-text options object.
 * @return React.ReactNode - The corresponding React elements for the rich text.
 */
export const renderContentfulRichText = (
  richText?: ContentfulRichText,
  options?: Options,
): React.ReactNode => {
  if (!richText) {
    return <></>;
  }

  const combinedContentfulOptions = {
    ...defaultOptions,
  };

  // combine default options with passed options
  if (options && options.renderMark) {
    combinedContentfulOptions.renderMark = {
      ...defaultOptions.renderMark,
      ...options.renderMark,
    };
  }
  if (options && options.renderNode) {
    combinedContentfulOptions.renderNode = {
      ...defaultOptions.renderNode,
      ...options.renderNode,
    };
  }
  if (options && options.renderText) {
    combinedContentfulOptions.renderText = options.renderText;
  }

  return (
    <>
      {renderRichText(
        richText as RenderRichTextData<ContentfulRichTextGatsbyReference>,
        combinedContentfulOptions,
      )}
    </>
  );
};
